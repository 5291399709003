/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/camelcase */

import posthog from "posthog-js";
import { cleaningNullValue } from "../orders";
import { recombeeClient } from "../../recombee/client";
import { AddCartAddition, AddPurchase, AddDetailView as RAddDetailView } from "recombee-js-api-client";
import { ECheckoutURL } from "@/hooks/useCheckout/types";
import { retryOperation } from "@/utils/retryOperation";

const RepurchaseIntent = ({ order_id, store_id }: RepurchaseProps): void => {
  posthog.capture("Repurchase Intent", {
    order_id,
    store_id,
  });
  window.gtag("event", "RepurchaseIntent", {
    order_id,
    store_id,
  });

  // Klaviyo
  window._learnq.push([
    "track",
    "RepurchaseIntent",
    {
      orderId: order_id,
      storeId: store_id,
    },
  ]);
};

const AddressInCoverage = async ({ coverageZoneId, storeId }: AddressInCoverageParams): Promise<void> => {
  await retryOperation(async () => {
    if (!window?._learnq) {
      throw new Error("Klaviyo not ready");
    }

    return window._learnq.push([
      "track",
      "AddressInCoverage",
      {
        coverageZoneId,
        storeId,
      },
    ]);
  });
};

const CoverageChange = ({
  lastAddress,
  lastCoverageZoneId,
  lastLat,
  lastLng,
  lastStoreId,
  newAddress,
  newCoverageZoneId,
  newLat,
  newLng,
  newStoreId,
}: CoverageChangeType): void => {
  posthog.capture("CoverageChange", {
    lastAddress,
    lastLat,
    lastLng,
    lastCoverageZoneId,
    lastStoreId,
    newAddress,
    newLat,
    newLng,
    newCoverageZoneId,
    newStoreId,
    hasStoreChanged: newStoreId !== lastStoreId,
  });
};

const viewBlogPost = async (blogPost: BlogPostAttr): Promise<any> => {
  const posthogPromise = posthog.capture("ViewBlogPost", {
    postName: blogPost.title.rendered,
    postLink: blogPost.link,
    postId: blogPost.id,
  });
  // Klaviyo
  const klaviyoPromise = window._learnq.push([
    "track",
    "ViewBlogPost",
    {
      postName: blogPost.title.rendered,
      postLink: blogPost.link,
      postId: blogPost.id,
    },
  ]);

  return await Promise.all([posthogPromise, klaviyoPromise]);
};

const ViewUnavailableProduct = ({
  product,
  storeId,
}: {
  product: ProductDetailsType;
  storeId: string | number;
}): void => {
  posthog.capture("ViewUnavailableProduct", {
    product: {
      id: product.details.id,
      sku: product.details.sku,
      brand: product.details.brand.name,
      description: product.details.description,
      name: product.details.name,
      publicId: product.details.publicId,
      slug: product.details.slug,
    },
    storeId,
  });
};

const CustomerCareMessage = ({ channel }: CustomerCareMessageParams): void => {
  posthog.capture("CustomerCareMessage", {
    channel,
  });
};
const CustomerCareIntention = (): void => {
  posthog.capture("CustomerCareIntention");
};

const ViewContent = ({ contentTypeName }: ViewContentParams): void => {
  posthog.capture("ViewContent", {
    content_type: contentTypeName,
  });
};

const OutOfCoverage = async ({ address, lat, lng }: any) => {
  posthog.capture("Out Of Coverage", {
    address,
    lat,
    lng,
  });
  window.gtag("event", "OutOfCoverage", {
    address,
    lat,
    lng,
  });

  // Klaviyo
  await retryOperation(async () => {
    if (!window?._learnq) {
      throw new Error("Klaviyo not ready");
    }

    return window._learnq.push([
      "track",
      "OutOfCoverage",
      {
        address,
        latitude: lat,
        longitude: lng,
      },
    ]);
  });
};

const ViewProduct = async ({ productInfo, productUrl }: ViewContentProps) => {
  const hasPinterestAppID = !["", " "].includes(import.meta.env.REACT_APP_PINTEREST_APP_ID || "");

  // posthog.capture("View Content", {
  //   content_ids: ids,
  //   content_category: category,
  //   content_name: name,
  //   content_type: type,
  //   contents,
  //   currency,
  //   value
  // });
  await Promise.all([
    // Facebook Pixel
    retryOperation(async () => {
      if (!window?.fbq) {
        throw new Error("Facebook Pixel not ready");
      }
      window.fbq("track", "ViewContent", {
        content_ids: [productInfo.details.sku],
        content_category: productInfo.details.categories?.edges[0]?.node?.name,
        content_name: productInfo.details.name,
        content_type: "product",
        currency: `${import.meta.env.REACT_APP_CURRENCY}`,
        value: productInfo.availability.priceUndiscounted.gross,
      });
    }),

    // klaviyo
    retryOperation(async () => {
      if (!window?._learnq) {
        throw new Error("Klaviyo not ready");
      }

      // Get all product categories to klaviyo
      const categories = productInfo.details.categories.edges.map((category) => category.node.name);

      window._learnq.push([
        "track",
        "Viewed Product",
        {
          ProductName: productInfo.details.name,
          ProductID: String(productInfo.details.sku),
          SKU: String(productInfo.details.sku),
          Categories: categories,
          ImageURL: productInfo.details.images[0]?.url,
          URL: productUrl,
          Brand: productInfo.details.brand.name,
          Price: productInfo.availability.priceUndiscounted.gross,
        },
      ]);
    }),

    // Frizbit

    retryOperation(async () => {
      if (!window?.frizbit) {
        throw new Error("Frizbit not ready");
      }

      window.frizbit?.track("productViewed", {
        product: {
          id: String(productInfo.details.sku),
          price: productInfo.availability.price.gross,
          salePrice: productInfo.availability.priceUndiscounted.gross,
          name: productInfo.details.name,
          categories,
          image: productInfo.details.images[0]?.url,
        },
      });
    }),

    // Pinterest
    retryOperation(async () => {
      if (!window?.pintrk) {
        throw new Error("Pinterest not ready");
      }
      if (hasPinterestAppID) {
        window.pintrk("track", "pagevisit");
      }
    }),
  ]);

  // window.gtag("event", "ViewContent", {
  //   content_ids: ids,
  //   content_category: category,
  //   content_name: name,
  //   content_type: type,
  //   contents,
  //   currency,
  //   value
  // });
};

const ProductListViewed = ({ products, screenName, listType }: ProductListViewdType) => {
  window.frizbit?.track("productsListViewed", {
    currency: import.meta.env.REACT_APP_CURRENCY,
    listName: screenName,
    listType,
    creative: products.length > 0 ? products[0].image : undefined,
    products,
  });
};

const AddDetailView = (productId, recommId): void => {
  /* Used primarly when user enters product screen */

  const userId = posthog.get_distinct_id();
  const recombeeOpts = {
    cascadeCreate: true,
  };
  if (recommId) {
    recombeeOpts.recommId = recommId;
  }
  void recombeeClient.send(new RAddDetailView(userId, String(productId), recombeeOpts));
};

const BannerClick = async ({ id, target, image, name }: BannerClickProps) => {
  posthog.capture("Banner Click", {
    id,
    target,
    image,
    name,
  });

  await Promise.all([
    retryOperation(async () => {
      if (!window?.gtag) {
        throw new Error("Google Tag Manager not ready");
      }
      window.gtag("event", "BannerClick", {
        id,
        target,
        image,
        name,
      });
    }),
    // Facebook Pixel
    retryOperation(async () => {
      if (!window?.fbq) {
        throw new Error("Facebook Pixel not ready");
      }
      window.fbq("track", "BannerClick", {
        id,
        target,
        image,
        name,
      });
    }),

    retryOperation(async () => {
      // Klaviyo
      if (!window?._learnq) {
        throw new Error("Klaviyo not ready");
      }
      window._learnq.push([
        "track",
        "BannerClick",
        {
          bannerId: id,
          target,
          bannerImage: image,
          bannerName: name,
        },
      ]);
    }),
  ]);
};

const promotedProduct = ({
  product_brand,
  product_id,
  product_name,
  product_sku,
  word,
  type,
}: promotedProductParams): void => {
  posthog.capture("promoted Product", {
    product_brand,
    product_id,
    product_name,
    product_sku,
    word,
    type,
  });
};

const SearchProduct = ({ query, word }: SearchProps): void => {
  const hasPinterestAppID = !["", " "].includes(import.meta.env.REACT_APP_PINTEREST_APP_ID || "");

  posthog.capture("Search Product", {
    query,
    word,
  });
  window.fbq("track", "Search", {
    search_string: query,
    value: word,
  });
  window.gtag("event", "Search", {
    search_string: query,
    value: word,
  });

  // Klaviyo
  window._learnq.push([
    "track",
    "Search",
    {
      searchString: query,
      searchedWord: word,
    },
  ]);

  if (hasPinterestAppID) {
    window.pintrk("track", "search", {
      search_query: word,
    });
  }
};

const CompleteRegistration = async ({ id, user }: CompleteRegistrationProps) => {
  const hasPinterestAppID = !["", " "].includes(import.meta.env.REACT_APP_PINTEREST_APP_ID || "");

  posthog.capture("Completed Registration", {
    id,
    user,
  });

  window.gtag("event", "CompleteRegistration", {
    content_name: user.firstName,
    status: "ACTIVE",
    id,
    user,
  });
  await Promise.all([
    // Facebook Pixel
    retryOperation(async () => {
      if (!window?.fbq) {
        throw new Error("Facebook Pixel not ready");
      }
      window.fbq("track", "CompleteRegistration", {
        content_name: user.firstName,
        status: "ACTIVE",
        currency: `${import.meta.env.REACT_APP_CURRENCY}`,
        value: 1,
      });
    }),

    // Pinterest
    retryOperation(async () => {
      if (!window?.pintrk) {
        throw new Error("Pinterest not ready");
      }
      if (hasPinterestAppID) {
        window.pintrk("track", "signup");
      }
    }),
  ]);
};

const AddPaymentInfo = async ({ id, description, payment_method }: PaymentMethodProps) => {
  posthog.capture("Add Payment", {
    id,
    payment_method,
    description,
  });

  window.gtag("event", "AddPaymentInfo", {
    content_ids: [id],
    value: payment_method,
    contents: description,
  });

  await Promise.all([
    // Facebook Pixel
    retryOperation(async () => {
      if (!window?.fbq) {
        throw new Error("Facebook Pixel not ready");
      }
      window.fbq("track", "AddPaymentInfo", {
        content_ids: [id],
        value: payment_method,
        contents: description,
      });
    }),

    // Klaviyo
    retryOperation(async () => {
      if (!window?._learnq) {
        throw new Error("Klaviyo not ready");
      }
      window._learnq.push([
        "track",
        "AddPaymentInfo",
        {
          paymentId: id,
          paymentMethod: payment_method,
          description,
        },
      ]);
    }),
  ]);
};

const AddToCart = async ({ productsInfo, cartProducts, recommId }: AddToCartProps) => {
  const hasPinterestAppID = !["", " "].includes(import.meta.env.REACT_APP_PINTEREST_APP_ID || "");

  /* Recombee setup */
  const userId = posthog.get_distinct_id();
  const recombeeOpts: Record<string, any> = {
    cascadeCreate: true,
    price: productsInfo.item?.price?.finalPrice,
  };
  if (recommId) {
    recombeeOpts.recommId = recommId;
  }
  const productId = productsInfo?.item?.product?.id;

  /* Content to FB */
  /* id and quantity need be part */
  // let content_fb = {
  //   id: data.item.details.sku,
  //   quantity:(data.item.quantity ? data.item.quantity: 1 )
  // }

  /* Calls */

  posthog.capture("Product Added", {
    product: productsInfo.item.product.id,
    name: productsInfo.item.product.name,
    type: "product",
    currency: productsInfo.item.price.currency,
    value: productsInfo.item.price.finalPrice,
  });

  // Get productCart on Klaviyo Format
  const klaviyoCartProducts = cartProducts.map((cartProduct: Cart) => {
    return {
      ProductID: `${cartProduct.product.sku}`,
      SKU: `${cartProduct.product.sku}`,
      ProductName: cartProduct.product.name,
      Quantity: cartProduct.quantity,
      ItemPrice: cartProduct.price.finalPrice,
      RowTotal: cartProduct.price.finalPrice * cartProduct.quantity,
      ProductURL: `${document.location.origin}/stores/market/p/${cartProduct.product.slug}`,
      ImageURL: cartProduct.product.image.url,
    };
  });

  // Add the new product
  klaviyoCartProducts.push({
    ProductID: `${productsInfo.item.product.sku}`,
    SKU: `${productsInfo.item.product.sku}`,
    ProductName: productsInfo.item.product.name,
    Quantity: productsInfo.quantity,
    ItemPrice: productsInfo.item.price.finalPrice,
    RowTotal: Number(productsInfo.item.price.finalPrice) * productsInfo.quantity,
    ProductURL: `${document.location.origin}/stores/market/p/${productsInfo.item.product.slug}`,
    ImageURL: productsInfo.item.product.image.url,
  });

  let total = 0;
  klaviyoCartProducts.forEach((product) => {
    total = total + product.RowTotal;
  });

  // Get cart products Names
  const productNames = cartProducts.map((cartProduct: Cart) => cartProduct.product.name);

  // add new product name
  productNames.push(productsInfo.item.product.name);

  // Pinterest
  const pinterestProducts = cartProducts.map((cartProduct: Cart) => {
    return {
      product_name: cartProduct.product.name,
      product_id: cartProduct.product.sku,
      product_price: cartProduct.price.finalPrice,
      product_quantity: cartProduct.quantity,
      product_brand: cartProduct?.product?.brand?.name || "",
    };
  });

  // Add the new product
  pinterestProducts.push({
    product_name: productsInfo.item.product.name,
    product_id: productsInfo.item.product.sku,
    product_price: productsInfo.item.price.finalPrice,
    product_quantity: productsInfo.quantity,
    product_brand: productsInfo.item?.product?.brand?.name || "",
  });

  // end Pinterest

  await Promise.all([
    retryOperation(async () => {
      // Google Tag Manager
      if (!window?.gtag) {
        throw new Error("Google Tag Manager not ready");
      }
      window.gtag("event", "AddToCart", {
        contents: productsInfo.item,
        content_ids: [productsInfo.item.product.id],
        content_name: productsInfo.item.product.name,
        content_type: "product",
        currency: productsInfo.item.price.currency,
        value: productsInfo.item.price.finalPrice,
      });
    }),

    // Facebook Pixel
    retryOperation(async () => {
      if (!window?.fbq) {
        throw new Error("Facebook Pixel not ready");
      }

      window.fbq("track", "AddToCart", {
        content_ids: [productsInfo.item.product.sku],
        content_name: productsInfo.item.product.name,
        // contents: content_fb,
        content_type: "product",
        currency: `${import.meta.env.REACT_APP_CURRENCY}`,
        value: productsInfo.item.price.finalPrice,
      });
    }),

    // klaviyo
    retryOperation(async () => {
      if (!window?._learnq) {
        throw new Error("Klaviyo not ready");
      }

      window._learnq.push([
        "track",
        "AddToCart",
        {
          $value: total,
          AddedItemProductName: productsInfo.item.product.name,
          AddedItemProductID: productsInfo.item.product.sku,
          AddedItemSKU: productsInfo.item.product.sku,
          AddedItemImageURL: productsInfo.item.product.image.url,
          AddedItemURL: `${document.location.origin}/stores/market/p/${productsInfo.item.product.slug}`,
          AddedItemPrice: productsInfo.item.price.originalPrice,
          AddedItemQuantity: productsInfo.quantity,
          ItemNames: productNames,
          CheckoutURL: `${document.location.origin}${ECheckoutURL.ADDRESS_PAGE}`,
          Items: klaviyoCartProducts,
        },
      ]);
    }),

    // frizbit
    retryOperation(async () => {
      if (!window?.frizbit) {
        throw new Error("Frizbit not ready");
      }
      window.frizbit?.track("productAddedToCart", {
        product: {
          brand: productsInfo.item.product.brand.name,
          currency: `${import.meta.env.REACT_APP_CURRENCY}`,
          description: productsInfo.item.product.description,
          id: productsInfo.item.product.sku,
          image: productsInfo.item.product.image.url,
          inStock: true,
          name: productsInfo.item.product.name,
          position: cartProducts.length,
          price: productsInfo.item.price.finalPrice,
          salePrice: productsInfo.item.price.originalPrice,
          quantity: productsInfo.quantity,
          url: `${document.location.origin}/stores/market/p/${productsInfo.item.product.slug}`,
        },
      });
    }),

    // Pinterest
    retryOperation(async () => {
      if (!window?.pintrk) {
        throw new Error("Pinterest not ready");
      }
      if (hasPinterestAppID) {
        window.pintrk("track", "addtocart", {
          value: total,
          order_quantity: pinterestProducts.length,
          currency: `${import.meta.env.REACT_APP_CURRENCY}`,
          line_items: pinterestProducts,
        });
      }
    }),
  ]);

  void recombeeClient.send(new AddCartAddition(userId, String(productId), recombeeOpts));
};

const CheckoutStarted = ({ products, total }: CheckoutStartedProps): void => {
  // filter product data
  const ids = products.map((item: any) => {
    return item.pk;
  });

  const skus = products.map((item: any) => {
    return item.details.sku;
  });

  const productsInfo = products.map((item: any) => {
    return {
      id: item.pk,
      currency: item.availability.price.currency,
      quantity: item.quantity,
      price: item.availability.price.gross,
    };
  });

  const content_fb = products.map((product: any, index: number) => {
    return {
      ...product,
      id: skus[index],
    };
  });

  //    To frizbit
  const frizbitProducts = products.map((product, index: number) => {
    return {
      name: product.product.name,
      id: skus[index],
      image: product.product.image.url,
    };
  });

  posthog.capture("Checkout Started", {
    content_ids: ids,
    contents: productsInfo,
    num_items: productsInfo.length,
    value: total,
  });
  window.fbq("track", "InitiateCheckout", {
    content_ids: skus,
    contents: content_fb,
    content_type: "product_group",
    num_items: productsInfo.length,
    currency: `${import.meta.env.REACT_APP_CURRENCY}`,
    value: total,
  });
  window.gtag("event", "InitiateCheckout", {
    content_ids: ids,
    contents: productsInfo,
    num_items: productsInfo.length,
    value: total,
  });

  // Klaviyo
  window._learnq.push([
    "track",
    "InitiateCheckout",
    {
      productIds: skus,
      numItems: products.length,
      $value: total,
      cartProducts: products,
    },
  ]);

  // frizbit
  window.frizbit?.track("InitiateCheckout", {
    products: frizbitProducts,
    numItems: products.length,
    currency: `${import.meta.env.REACT_APP_CURRENCY}`,
    value: total,
  });
};

const OrderCompleted = ({ currency, value, products, order, coupon, storeId }: OrderCompletedProps): void => {
  const hasPinterestAppID = !["", " "].includes(import.meta.env.REACT_APP_PINTEREST_APP_ID || "");
  // Pre processing;
  //    To FB-Pixel
  const skus = products.map((item: any) => {
    return item.product_sku;
  });

  //    To frizbit
  const frizbitProducts = products.map((item: any) => {
    return {
      id: item.product_sku,
      name: item.product_name,
      sku: item.product_sku,
    };
  });

  //    To Pinterest
  const pinterestProducts = products.map((item: ProductsPurchase) => {
    return {
      product_name: item.product_name,
      product_id: item.product_sku,
      // product_category: item.categories.map((category) => category ).join(", "),
      product_price: item.cost_price,
      product_quantity: item.quantity,
    };
  });

  /* Recombee setup */

  const userId = posthog.get_distinct_id();

  /* Calls */

  posthog.capture("Order Completed", {
    currency,
    value,
    products,
    coupon: coupon ? coupon.code : null,
  });
  window.gtag(
    "event",
    "purchase",
    cleaningNullValue({
      transaction_id: order.id,
      affiliation: order.store.name,
      currency,
      value,
      coupon: coupon ? coupon.code : null,
      tax: order.items[0].tax,
      shipping: order.total_service.gross,
      items: order.items.map((item) => {
        return cleaningNullValue({
          id: item.id,
          name: item.product_name,
          brand: item.product_brand,
          category: item.categories.join("/"),
          sku: item.product_sku,
          quantity: item.quantity,
          price: item.unit_price_gross,
        });
      }),
    })
  );
  products.forEach((item) => {
    const recombeeOpts = {
      cascadeCreate: true,
      amount: item.quantity,
      price: Number(item.unit_price_gross) * item.quantity,
    };
  });

  window.fbq("track", "Purchase", {
    content_ids: skus,
    content_type: "product_group",
    // contents: products,
    currency: `${import.meta.env.REACT_APP_CURRENCY}`,
    num_items: products.length,
    value,
    coupon: coupon ? coupon.code : null,
    storeId,
  });

  window.frizbit?.track("productsPurchased", {
    products: frizbitProducts,
    coupon: coupon ? coupon.code : null,
    currency,
    discount: order.discount_amount.gross,
    orderId: order.id,
    total: value,
  });

  if (hasPinterestAppID) {
    window.pintrk("track", "checkout", {
      value,
      order_quantity: products.length,
      currency,
      order_id: order.id,
      line_items: pinterestProducts,
    });
  }
};

const LoginAttempt = ({ email, agent, password }: LoginAttemptParams): void => {
  posthog.capture("Login Attempt", {
    email,
    agent,
  });
  window.gtag("event", "Login Attempt");
};

const Reset = async () => {
  // window.analytics.reset();
  posthog.reset();

  await Promise.all([
    // Google Tag
    retryOperation(async () => {
      if (!window?.gtag) {
        throw new Error("Google Tag Manager not ready");
      }
      window.gtag("set", "userId", "");
    }),

    // Crisp
    retryOperation(async () => {
      if (!window?.$crisp) {
        throw new Error("Crisp not ready");
      }
      window.CRISP_TOKEN_ID = "";
      window.$crisp.push(["do", "session:reset"]);
    }),
  ]);
};

const AddPaymentPseEvent = ({
  email,
  orderId,
  userId,
}: {
  email: string;
  orderId: string;
  userId: string;
}) => {
  posthog.capture("payment-pse-button", {
    email,
    order_id: orderId,
    user_id: userId,
  });
};

const paymentPse = {
  buttonClick: ({ email, orderId, userId }: { email: string; orderId: string; userId: string }) => {
    posthog.capture("payment-pse-button", {
      email,
      order_id: orderId,
      user_id: userId,
    });
  },
  buttonFormReady: ({
    email,
    orderId,
    userId,
    hasToken,
  }: {
    email: string;
    orderId: string;
    userId: string;
    hasToken: boolean;
  }) => {
    posthog.capture("payment-pse-button-form-ready", {
      email,
      order_id: orderId,
      user_id: userId,
      has_token: hasToken,
    });
  },
  buttonFormError: ({
    email,
    orderId,
    userId,
    hasToken,
  }: {
    email: string;
    orderId: string;
    userId: string;
    hasToken: boolean;
  }) => {
    posthog.capture("payment-pse-button-form-error", {
      email,
      order_id: orderId,
      user_id: userId,
      has_token: hasToken,
    });
  },
};

export default {
  AddToCart,
  CheckoutStarted,
  SearchProduct,
  BannerClick,
  Reset,
  AddDetailView,
  AddressInCoverage,
  OrderCompleted,
  AddPaymentInfo,
  CompleteRegistration,
  ViewProduct,
  OutOfCoverage,
  RepurchaseIntent,
  viewBlogPost,
  CoverageChange,
  ProductListViewed,
  ViewContent,
  CustomerCareIntention,
  CustomerCareMessage,
  ViewUnavailableProduct,
  promotedProduct,
  LoginAttempt,
  paymentPse,
};
